import { ReactNode, SVGProps } from 'react'
import { twJoin } from 'tailwind-merge'

import {selectors} from "@/common/constants/selectors-constants";

export interface USPAreaItem {
  icon: (props: SVGProps<SVGSVGElement>) => ReactNode
  title: string
  subtitle: string
}

interface USPAreaComponentProps {
  items: USPAreaItem[]
}

export const USPAreaComponent = ({ items }: USPAreaComponentProps) => {
  return (
    <ul
      className={twJoin(
        'grid sm:grid-cols-2 lg:grid-cols-4',
        'mt-6 pb-6 lg:pb-0 pl-6 lg:pl-0 pr-6 lg:pr-0 mb-1 lg:mb-6 -ml-5 lg:ml-0 -mr-5 lg:mr-0',
        'border-b-4 border-b-black border-b-solid lg:border-b-0',
      )}
      data-test={selectors.HP.benefits}
    >
      {items.map((item, index) => (
        <li
          className={twJoin(
            index < items.length - 1 ? 'mb-5' : 'mb-0',
            index < items.length / 2 ? 'sm:mb-5' : 'sm:mb-0',
            'lg:mb-0 flex',
          )}
          key={`USP_${index}`}
        >
          <item.icon className="shrink-0 mr-2" />
          <div className="pr-2">
            <p className="text-base md:text-sm md:leading-tight">
              <strong
                dangerouslySetInnerHTML={{ __html: item.title }}
                className="text-[15px]"
              />
            </p>
            <p
              className="text-[10px] md:text-[13px] md:leading-relaxed"
              dangerouslySetInnerHTML={{ __html: item.subtitle }}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}
