'use client'

import React, { useMemo } from 'react'

import { ProductStockStatus } from '@/api'
import {
  ProductListItemData,
  obsoleteIsConfigurableProductListItem,
  obsoleteIsGiftCardProductListItem,
} from '@/common/types/product-types'
import { ProductDetailStockSubscribe } from '@/modules/product/components/detail/components/stock-subscribe'
import { Dialog } from '@/components/dialog/dialog'
import { ObsoletePurchaseDialogConfigurableVariants } from './obsolete-purchase-dialog-configurable-variants'
import { ObsoletePurchaseDialogGiftCardVariants } from './obsolete-purchase-dialog-gift-card-variants'

export type PurchaseDialogProps = {
  children?: JSX.Element
  title?: string
  productData?: ProductListItemData
  isVisible: boolean
  onClose?: () => void
}

export function ObsoletePurchaseDialog({
  onClose,
  productData,
  isVisible,
}: PurchaseDialogProps): JSX.Element | null {
  const isLongTermUnavailable = productData?.long_term_unavailable !== 0
  const isSomeVariantAvailable = useMemo(
    () =>
      productData !== undefined &&
      obsoleteIsConfigurableProductListItem(productData) &&
      productData.configurable_variants?.some(
        (variant) =>
          variant?.product?.enabled &&
          variant?.product?.stock_status === ProductStockStatus.InStock,
      ),
    [productData],
  )
  const inStock = useMemo(
    () =>
      !isLongTermUnavailable &&
      (obsoleteIsGiftCardProductListItem(productData) ||
        isSomeVariantAvailable),

    [isLongTermUnavailable, productData, isSomeVariantAvailable],
  )

  return productData ? (
    <Dialog onClose={onClose} isVisible={isVisible} maxWidth={500}>
      {inStock ? (
        <>
          {obsoleteIsConfigurableProductListItem(productData) && (
            <ObsoletePurchaseDialogConfigurableVariants
              configurableProductData={productData}
              isLongTermUnavailable={isLongTermUnavailable}
              onClose={onClose}
              isInStock
            />
          )}
          {obsoleteIsGiftCardProductListItem(productData) && (
            <ObsoletePurchaseDialogGiftCardVariants
              productData={productData}
              onClose={onClose}
            />
          )}
        </>
      ) : (
        <ProductDetailStockSubscribe />
      )}
    </Dialog>
  ) : null
}
