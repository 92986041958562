'use client'

import { HomepageBannerSliderType } from '@/api'
import { SliderComponent } from './slider-component'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import {
  BreakPointValues,
  useIsBelowBreakpointBrowser,
} from '@/common/hooks/use-is-browser-resolution'
export type HomepageBannerProps = {
  isDeviceMobile: boolean
  homepageSliderData: HomepageBannerSliderType
}

export function HomepageBanner({
  homepageSliderData,
  isDeviceMobile,
}: HomepageBannerProps): JSX.Element {
  const isSm = useIsBelowBreakpointBrowser(BreakPointValues.Sm)

  return (
    <SliderComponent
      items={homepageSliderData?.items ?? []}
      isMobile={isDeviceMobile || isSm}
      config={homepageSliderData.config}
      onClick={(_slide, position) => gtmTracker.trackBannerClick(position)}
    />
  )
}
