'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'

import { Box } from '@/components/box'
import { ObsoloteProductListItem } from '@/components/obsolote-product-list-item/obsolote-product-list-item'
import { ObsoloteProductDataContextProvider } from '@/providers'
import { ObsoloteProductListItemSkeleton } from '@/components/obsolote-product-list-item/obsolote-product-list-item-skeleton'
import {
  ProductListItemData,
  obsoleteIsConfigurableProductListItem,
  obsoleteIsGiftCardProductListItem,
} from '@/common/types/product-types'
import { getQueryClientServer } from '@/common/services'
import {
  ProductStockStatus,
  useLastViewedProductsQuery,
  useTopProductsQuery,
} from '@/api'
import { SKU_RECENTLY_VIEWED_PRODUCT } from '@/providers/product-data/utils'
import { selectors } from '@/common/constants/selectors-constants'

export type HomepageProductsGridProps = {
  onPurchaseVariant: (product: ProductListItemData) => void
}

export function HomepageProductsGrid({
  onPurchaseVariant,
}: HomepageProductsGridProps): JSX.Element {
  const [topProductsData, setTopProductsData] = useState<ProductListItemData[]>(
    [],
  )
  const [lastViewedProductsData, setLastViewedProductsData] = useState<
    ProductListItemData[] | undefined
  >()

  const lastViewedProductsSkus: string[] = useMemo(() => {
    try {
      return JSON.parse(
        localStorage.getItem(SKU_RECENTLY_VIEWED_PRODUCT) ?? '[]',
      )
    } catch (e) {
      return []
    }
  }, [])

  const lastViewedProductsUIds = useMemo(
    () =>
      lastViewedProductsData
        ?.map((product) => product?.uid)
        .filter(Boolean) as string[],
    [lastViewedProductsData],
  )

  // filter by UID because the SKU is NOT UNIQUE
  const filteredTopProductsItems = useMemo(
    () =>
      topProductsData?.filter(
        (topProductsItem) =>
          !!topProductsItem?.uid &&
          !lastViewedProductsUIds.includes(topProductsItem?.uid),
      ) ?? [],
    [lastViewedProductsUIds, topProductsData],
  )

  const finalProducts = useMemo(
    () =>
      lastViewedProductsData
        ?.reverse()
        .concat(
          filteredTopProductsItems.filter(Boolean) as ProductListItemData[],
        )
        .slice(0, 8),
    [lastViewedProductsData, filteredTopProductsItems],
  )

  const loadData = useCallback(async (lastViewedProductsSkus: string[]) => {
    const queryClient = getQueryClientServer()
    const [lastViewedProductsQuery, topProductsQuery] = await Promise.all([
      lastViewedProductsSkus.length > 0
        ? queryClient.fetchQuery({
            queryKey: useLastViewedProductsQuery.getKey({
              skus: lastViewedProductsSkus,
            }),
            queryFn: useLastViewedProductsQuery.fetcher({
              skus: lastViewedProductsSkus,
            }),
          })
        : undefined,
      queryClient.fetchQuery({
        queryKey: useTopProductsQuery.getKey(),
        queryFn: useTopProductsQuery.fetcher(),
      }),
    ])

    const lastViewedProducts = (lastViewedProductsSkus || [])
      .map((lvpSku: string) =>
        (
          (lastViewedProductsQuery?.products?.items as ProductListItemData[]) ||
          []
        ).find((item) => item?.sku === lvpSku),
      )
      .filter(Boolean)
      .reverse()

    setLastViewedProductsData(
      (lastViewedProducts ?? []).slice(0, 4) as ProductListItemData[],
    )

    const topProductsItems = topProductsQuery?.categories?.items

    setTopProductsData(
      (topProductsItems &&
        topProductsItems[0] &&
        (topProductsItems[0]?.products?.items as ProductListItemData[])) ??
        [],
    )
  }, [])

  useEffect(() => {
    if (lastViewedProductsSkus) {
      loadData(lastViewedProductsSkus)
    }
  }, [lastViewedProductsSkus, loadData])

  return (
    <Box
      className="w-full grid grid-cols-2 md:grid-cols-4 gap-5 my-5"
      data-test={selectors.HP.bestsellers}
    >
      {finalProducts
        ? finalProducts.map((finalProduct) => {
            const isMultiVariantConfigurableProduct =
              obsoleteIsConfigurableProductListItem(finalProduct) &&
              finalProduct.configurable_variants &&
              finalProduct.configurable_variants.length > 1
            const isGiftCard = obsoleteIsGiftCardProductListItem(finalProduct)

            if (isMultiVariantConfigurableProduct || isGiftCard) {
              return (
                <ObsoloteProductDataContextProvider
                  productId={finalProduct.id}
                  productSku={finalProduct.sku}
                  outOfStock={
                    finalProduct.stock_status === ProductStockStatus.OutOfStock
                  }
                  configurableVariants={
                    obsoleteIsConfigurableProductListItem(finalProduct)
                      ? finalProduct.configurable_variants
                      : undefined
                  }
                  giftCardAmounts={
                    obsoleteIsGiftCardProductListItem(finalProduct)
                      ? finalProduct.giftcard_amounts
                      : undefined
                  }
                  key={`product_hp_grid_${finalProduct.uid}`}
                >
                  <ObsoloteProductListItem
                    product={finalProduct}
                    onPurchase={onPurchaseVariant}
                  />
                </ObsoloteProductDataContextProvider>
              )
            } else {
              return (
                <ObsoloteProductListItem
                  key={`product_hp_grid_${finalProduct?.uid}`}
                  product={finalProduct}
                />
              )
            }
          })
        : Array.from({ length: 8 }).map((_, index) => (
            <ObsoloteProductListItemSkeleton key={index} />
          ))}
    </Box>
  )
}
