'use client'

import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import {
  BlogPosts,
  CategoriesByGoalQuery,
  CategoryByGoal,
  NewestBlogPostsQuery,
} from '@/api'
import { Box } from '@/components/box'
import {
  BagIcon,
  HeartIcon,
  PackageIcon,
  SandWatchIcon,
} from '@/components/icons/svg'
import { BlogComponent } from './components/blog/blog-component'
import { CategoriesByGoal } from './components/categories-by-goal/categories-by-goal-component'
import { HomepageProducts } from './components/products/homepage-products'
import { USPAreaComponent } from './components/usp-area/usp-area-component'
import { useHomepageTracking } from './components/tracking/homepage-tracking'

export type HomepageClientProps = {
  categoriesByGoalQuery: CategoriesByGoalQuery
  newestBlogPostsQuery: NewestBlogPostsQuery
}

export function HomepageClient({
  categoriesByGoalQuery,
  newestBlogPostsQuery,
}: HomepageClientProps): JSX.Element {
  const t = useTranslations('USP')
  const categoriesByGoalData = (categoriesByGoalQuery?.categoriesByGoal ||
    []) as CategoryByGoal[]
  const newestBlogPostsData = (newestBlogPostsQuery?.NewestBlogPosts ??
    {}) as BlogPosts

  useHomepageTracking()

  return (
    <div>
      <Box
        className={twJoin(
          'container max-w-[940px] mx-auto',
          'flex flex-col flex-1',
          'px-5 pb-20 lg:px-0',
        )}
      >
        <USPAreaComponent
          items={[
            {
              icon: HeartIcon,
              title: t('box1.title'),
              subtitle: t('box1.subtitle'),
            },
            {
              icon: SandWatchIcon,
              title: t('box2.title'),
              subtitle: t('box2.subtitle'),
            },
            {
              icon: PackageIcon,
              title: t('box3.title'),
              subtitle: t('box3.subtitle'),
            },
            {
              icon: BagIcon,
              title: t('box4.title'),
              subtitle: t('box4.subtitle'),
            },
          ]}
        />
        {categoriesByGoalData.length > 0 && (
          <CategoriesByGoal categories={categoriesByGoalData} />
        )}
        <HomepageProducts />
        <BlogComponent posts={newestBlogPostsData} />
      </Box>
    </div>
  )
}
