import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'
import { useSearchParams } from 'next/navigation'

import { CategoryByGoal } from '@/api'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { selectors } from '@/common/constants/selectors-constants'
import {
  convertAbsoluteUrlToRelative,
  replaceFirstUaRuLocaleInUrl,
} from '@/common/utils/url-utils'
import { Link } from '@/components/link/link'
import { CategoryId } from '@/common/constants/categories'
import { SearchParams } from '@/common/constants/url-constants'

export type CategoriesByGoalProps = {
  categories: CategoryByGoal[]
  title?: string
}

const CategoriesToRemoveForTikTok = [
  CategoryId.LoseWeight,
  CategoryId.BuildMuscle,
]

export function CategoriesByGoal({
  categories,
  title,
}: CategoriesByGoalProps): JSX.Element {
  const t = useTranslations('CategoriesByGoal')
  const searchParams = useSearchParams()
  const isTikTok = searchParams.has(SearchParams.TikTok)

  const filteredCategories = isTikTok
    ? categories.filter(
        ({ category }) =>
          category?.uid &&
          !CategoriesToRemoveForTikTok.includes(category.uid as CategoryId),
      )
    : categories

  return (
    <div className="w-full bg-white text-grey-200 mb-4">
      <div className="page-title-wrapper">
        <h3
          data-test={selectors.HP.categoriesHeading}
          className="page-title mb-3 mt-3 text-black font-bold text-[15px] leading-4"
        >
          {title ?? t('title')}
        </h3>
      </div>

      <ul
        data-test={selectors.HP.categoriesByGoal}
        className="flex flex-wrap p-0 mx-[-5px]"
      >
        {filteredCategories.map(
          (categoryByGoal, index) =>
            categoryByGoal.category && (
              <li
                key={categoryByGoal.custom_name}
                onClick={() => gtmTracker.trackCategoryClick(index + 1)}
                className="flex flex-col items-center flex-[calc(50%-10px)] lg:flex-[calc(25%-10px)] overflow-hidden sm:bg-[#e9e9e9] m-1"
              >
                <Link
                  href={
                    categoryByGoal.category.url
                      ? convertAbsoluteUrlToRelative(
                          new URL(
                            replaceFirstUaRuLocaleInUrl(
                              categoryByGoal.category.url,
                            ),
                          ),
                        )
                      : ''
                  }
                  className="h-full w-full sm:flex items-center hover:underline justify-between"
                  aria-label={categoryByGoal.custom_name ?? 'breadcrumb link'}
                >
                  {categoryByGoal.category.thumbnail && (
                    <Image
                      src={`https://gymbeam.cz/media/catalog/category/${categoryByGoal.category.thumbnail}`}
                      alt={categoryByGoal.custom_name ?? ''}
                      width={400}
                      height={400}
                      className="max-w-400 max-h-400 sm:w-[75px] sm:h-[75px] bg-[#e9e9e9]"
                    />
                  )}
                  <span
                    className={twJoin([
                      'block sm:inline',
                      'w-[100%] ',
                      'p-4 sm:pt-0 sm:pb-0 sm:pl-2 sm:pr-1',
                      'text-center lg:text-left',
                      'text-[#666] sm:uppercase text-xs font-bold',
                    ])}
                    dangerouslySetInnerHTML={{
                      __html: categoryByGoal.custom_name ?? '',
                    }}
                  />
                </Link>
              </li>
            ),
        )}
      </ul>
    </div>
  )
}
