'use client'

import { useCallback, useEffect, useRef, useState } from 'react'

import { useClickOutside } from '@/common/hooks/click-outside.hook'
import { KeyCode } from '@/common/types/key-codes'
import { Button } from '@/components/button'
import { selectors } from '@/common/constants/selectors-constants'

export type DialogProps = {
  children?: JSX.Element
  title?: string
  okButtonTitle?: string
  okButtonEnabled?: boolean
  cancelButtonTitle?: string
  cancelButtonEnabled?: boolean
  isVisible: boolean
  maxWidth?: number | string
onOk?: () => void
  onCancel?: () => void
  onClose?: () => void
}

export function Dialog({
  children,
  isVisible,
  title,
  onClose,
  okButtonTitle,
  onOk,
  okButtonEnabled,
  cancelButtonTitle,
  cancelButtonEnabled,
  maxWidth = 'md',
  onCancel,
}: DialogProps): JSX.Element | null {
  const [isVisibleLocal, setVisibility] = useState<boolean>(isVisible)
  const dialogRef = useRef<HTMLDivElement | null>(null)

  const hide = useCallback(() => {
    setVisibility(false)

    if (onClose) {
      onClose()
    }
  }, [onClose])

  const confirm = useCallback(() => {
    if (onOk) {
      onOk()
    }

    hide()
  }, [hide, onOk])

  useEffect(() => {
    document.onkeydown = function (event) {
      event = event || window.event

      if (event.key === KeyCode.Escape) {
        hide()
      }
    }
  }, [hide])

  const { registerClickOutsideDetection } = useClickOutside()

  useEffect(() => {
    const reset = registerClickOutsideDetection(dialogRef.current, () => hide())

    return () => reset() 
  }, [registerClickOutsideDetection, hide])

  return isVisibleLocal ? (
    <div
      role="dialog"
      className="fixed z-50 inset-0 bg-black bg-opacity-75 overflow-y-auto h-full w-full px-2"
      data-test={selectors.common.dialog.dialogCenter}
    >
      <div
        className="relative top-14 mx-auto shadow-xl bg-white w-auto"
        style={{ maxWidth }}
        ref={dialogRef}
      >
        <div className="flex justify-end p-2 pb-0">
          <button
            onClick={hide}
            type="button"
            className="text-gray-900 bg-transparent hover:text-gray-400 font-light align-middle leading-6 text-4xl p-1.5 ml-auto inline-flex items-center"
          >
            &times;
          </button>
        </div>

        <div className="p-5 pt-0 text-center" data-test="dialog-center">
          {title && (
            <h3 className="text-xl font-normal text-gray-500 mt-5 mb-6">
              {title}
            </h3>
          )}

          {children}

          {cancelButtonEnabled && (
            <Button
              size="sm"
              variant="secondary"
              onClick={onCancel}
              data-modal-toggle="delete-user-modal"
            >
              {cancelButtonTitle || 'Cancel'}
            </Button>
          )}
          {okButtonEnabled && (
            <Button size="sm" variant="primary" onClick={confirm}>
              {okButtonTitle || 'OK'}
            </Button>
          )}
        </div>
      </div>
    </div>
  ) : null
}
